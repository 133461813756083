<template>
	<v-sheet flat class="mt-12">
		<v-form v-if="!sent">
			<v-text-field
				label="Email"
				v-model="email"
			></v-text-field>
			<v-textarea
				label="Message"
				v-model="message"
			></v-textarea>
            <p class="text-right">
                <v-btn color="primary" @click="submit">Send</v-btn>
            </p>
		</v-form>
		<v-alert color="lightest" v-else>
			Message Sent!
		</v-alert>
	</v-sheet>
</template>

<script>
export default {
	name: "ContactForm",
	data: () => ({
		sent: false,
		email: "",
		message: "",
	}),
	computed: {
		auth() {
			return this.$store.state.auth.data;
		},
	},
	watch: {
		auth: {
			immediate: true,
			handler() {
				let email = this.auth.email;
				if (email) {
					this.email = email;
				}
			},
		},
	},
	methods: {
		submit() {
			const self = this;
			self.$store
				.dispatch("emails/contactForm", {
					email: self.email,
					message: self.message,
				})
				.then(() => {
					self.sent = true;
				});
		},
	},
};
</script>
